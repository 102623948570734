import React from "react"
import { Link } from "gatsby"
import { Box, Grid } from "@material-ui/core"

import { NewsQuery } from "../../types/graphql-types"

import PostCard from "../components/PostCard"

type Props = {
  posts: NewsQuery
}

const Posts: React.FC<Props> = props => {
  return (
    <Box>
      <Grid container spacing={3}>
        {props.posts.allMicrocmsPosts.edges.map(({ node }) => {
          const categoryName = node.category?.name || ""
          const eyecatchUrl = node.eyecatch?.url || ""
          const slug = node.slug || ""
          const title = node.title || ""

          return (
            <Grid item lg={6} md={6} sm={6} xs={12} key={slug}>
              <Link to={`/posts/${slug}`}>
                <PostCard
                  categoryName={categoryName}
                  eyecatchUrl={eyecatchUrl}
                  publishedAt={node.publishedAt}
                  title={title}
                />
              </Link>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default Posts
