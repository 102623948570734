import React from "react"
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core"

type Props = {
  categoryName: string
  eyecatchUrl: string
  publishedAt: string
  title: string
}

const PostCard: React.FC<Props> = props => {
  return (
    <Card>
      <CardActionArea>
        <CardMedia image={props.eyecatchUrl} style={{ height: "200px" }} />

        <CardContent>
          <Typography
            component="h2"
            gutterBottom
            variant="h6"
            style={{ height: "100px" }}
          >
            {props.title}
          </Typography>

          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Chip color="primary" label={props.categoryName} />
            </Grid>

            <Grid item>
              <Typography component="time" variant="body1">
                {props.publishedAt}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default PostCard
