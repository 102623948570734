import React from "react"

import Layout from "../components/Layout"
import News from "../components/News"
import SEO from "../components/SEO"

const Home = () => {
  return (
    <>
      <SEO />
      <Layout hero={true}>
        <News />
      </Layout>
    </>
  )
}

export default Home
