import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Box, Button, Typography } from "@material-ui/core"

import { NewsQuery } from "../../types/graphql-types"

import Posts from "../components/Posts"

const News: React.FC = () => {
  const query: NewsQuery = useStaticQuery(graphql`
    query News {
      allMicrocmsPosts(sort: { fields: publishedAt, order: DESC }, limit: 6) {
        edges {
          node {
            category {
              name
            }
            content {
              product {
                eyecatch {
                  url
                }
                feature
                name
                url
              }
            }
            eyecatch {
              url
            }
            id
            postsId
            publishedAt(formatString: "YYYY/MM/DD")
            slug
            title
          }
        }
      }
    }
  `)

  return (
    <Box component="section">
      <Typography component="h2" gutterBottom variant="h5">
        新着記事
      </Typography>

      <Posts posts={query} />

      <Box mt={4} textAlign="right">
        <Link to="/posts">
          <Button color="secondary" size="large" variant="outlined">
            記事一覧を見る
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

export default News
